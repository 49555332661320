<template>
  <modal
    :width="1250"
    :adaptive="true"
    class="newBusinessModal CEModal modal-scroll-bar"
    name="newBusinessModal"
  >
    <div class="text-center m-t-20">
      <h2>{{ $t('MANAGEMENT.NEW_BUSINESS') }}</h2>
    </div>
    <div class="modal-content">
      <div class="reseller-info__container">
        <div class="d-flex align-items-center">
          <v-checkbox
            color="#0D3856"
            class="cus-checkbox"
            v-model="isAsDistribute"
            id="isAsDistribute"
          ></v-checkbox>
          <label class="px10 fs16 color-primary" for="isAsDistribute">{{
            $t('REGISTER_RESELLER.DEFINE_SELLER_AS_A_DISTRIBUTOR')
          }}</label>
        </div>
      </div>
      <p class="fs14 m-b-20 px20 color-grey"><i>
        {{ $t('REGISTER_RESELLER.AS_DISTRIBUTE_WARNING_TXT') }}
      </i></p>
      <div
        class="reseller-info__container"
      >
        <div class="reseller-info__wrapper">
          <div class="reseller-info__company reseller-info__case validation-form-group"
            :class="{ 'form-group--error': $v.newReseller_data.name.$error }"
          >
            <label class="label reseller-info__label"
              >{{ $t('REGISTER_RESELLER.BUSINESS_NAME') }} *</label
            >
            <input
              class="input"
              type="text"
              v-model="newReseller_data.name"
              @input="$v.newReseller_data.name.$touch"
            />
            <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newReseller_data.name.required">
            {{ $t('VALIDATION.REQUIRED_FIELD') }}
            </div>
          </div>

          <div class="reseller-info__dealer reseller-info__case validation-form-group"
            :class="{ 'form-group--error': $v.newReseller_data.business_license_number.$error }">
            <label class="label reseller-info__label"
              >{{ $t('REGISTER_RESELLER.AUTHORIZED_DEALER_HP') }} *</label
            >
            <input
              class="input"
              type="text"
              v-model="newReseller_data.business_license_number"
              @input="$v.newReseller_data.business_license_number.$touch"
              @keypress="isNumber($event)"
            />
            <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newReseller_data.business_license_number.required">
              {{ $t('VALIDATION.REQUIRED_FIELD') }}
            </div>
          </div>

          <div class="reseller-info__type reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('REGISTER_RESELLER.AGENT_RESPONSIBLE')
            }}</label>
            <vue-select
              :options="agent_id.options"
              class="input cus-select"
              v-model="agent_id.selected"
            ></vue-select>
          </div>

          <div class="reseller-info__phone-reseller reseller-info__case validation-form-group"
            :class="{ 'form-group--error': $v.newReseller_data.phone_number.$error }">
            <label class="label reseller-info__label">{{
              $t('REGISTER_RESELLER.BUSINESS_PHONE')
            }} *</label>
            <input
              class="input"
              type="text"
              v-model="newReseller_data.phone_number"
              @input="$v.newReseller_data.phone_number.$touch"
              @keypress="isNumber($event)"
            />
            <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newReseller_data.phone_number.required">
              {{ $t('VALIDATION.REQUIRED_FIELD') }}
            </div>
          </div>

          <div class="reseller-info__phone-contact reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('REGISTER_RESELLER.MOBILE_PHONE_CONTACT')
            }}</label>
            <input
              class="input"
              type="tel"
              v-model="newReseller_data.second_phone_number"
            />
          </div>

          <div
            class="d-flex justify-content-between align-items-center flex-wrap"
          >
            <div>
              <div class="d-flex align-items-center">
                <v-checkbox
                  color="#0D3856"
                  class="cus-checkbox"
                  v-model="newReseller_data.section_20"
                  v-if="distribute_section_20"
                ></v-checkbox>
                <v-checkbox
                  color="#0D3856"
                  class="cus-checkbox"
                  v-model="newReseller_data.section_20"
                  v-else
                  disabled
                ></v-checkbox>
                <label class="px10 fs16 color-primary">{{
                  $t('REGISTER_RESELLER.ARTICLE', { num: '20' })
                }}</label>
              </div>
              <!-- <div class="d-flex align-items-center">
                <v-checkbox color="#0D3856" class="cus-checkbox"></v-checkbox>
                <label class="px10 fs16 color-primary">{{
                  $t('BUSINESS_DETAILS.MULTILINE_POSITION')
                }}</label>
              </div> -->
            </div>
            <!-- <div class="d-flex justify-content-between">
              <div class="customer-number">
                <label class="label fs14">{{
                  $t('MANAGEMENT.SHANIV_CUSTOMER_NUMBER')
                }}</label>
                <input class="input" type="text" />
              </div>
              <div class="customer-number">
                <label class="label fs14">{{
                  $t('MANAGEMENT.SASA_CUSTOMER_NUMBER')
                }}</label>
                <input class="input" type="text" />
              </div>
            </div> -->
          </div>
          <div v-if="shanivCompanyList && shanivCompanyList.length > 0" v-for="(shaniv_company, index) in shanivCompanyList" class="reseller-info__shaniv-plname reseller-info__case">
            <label class="label reseller-info__label">
              {{ $t('BUSINESS_DETAILS.SHANIV_PLNAME', {company: shaniv_company.name}) }}
            </label>
            <vue-select
              :options="shaniv_company.PLNAMEs || []"
              class="input cus-select"
              :placeholder="shaniv_company.PLNAME_default || ''"
              v-model="newReseller_data[`PLNAME_${shaniv_company.name}`]"
            ></vue-select>
          </div>
        </div>

        <div class="reseller-info__wrapper">
          <div class="reseller-info__adress reseller-info__case validation-form-group"
            :class="{ 'form-group--error': $v.newReseller_data.city.$error }">
            <label class="label reseller-info__label">{{
              $t('COMMON.ADDRESS')
            }} *</label>
            <vue-google-autocomplete
              id="map3"
              classname="input"
              placeholder="הזן כתובת מלאה"
              v-on:placechanged="getAddressDataReseller"
              :country="['il']"
              v-model="googleAddressInput"
              @input="$v.newReseller_data.city.$touch"
            >
            </vue-google-autocomplete>
            <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newReseller_data.city.required">
              {{ $t('VALIDATION.REQUIRED_FIELD') }}
            </div>
          </div>
          <!-- <div class="reseller-info__adress reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('COMMON.CITY')
            }}</label>
            <input class="input" type="text" v-model="newReseller_data.city"/>
          </div>

          <div class="reseller-info__adress reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('COMMON.ADDRESS')
            }}</label>
            <input
              class="input"
              type="text"
              v-model="newReseller_data.street"
            />
          </div> -->

          <!-- <div class="reseller-info__post reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('COMMON.POSTAL_CODE')
            }}</label>
            <input
              class="input"
              type="text"
              v-model="newReseller_data.zip_code"
            />
          </div> -->

          <div class="reseller-info__work reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('COMMON.ACTIVITY_TIME')
            }}</label>

            <div class="reseller-info__work-wrapper">
              <div class="reseller-info__work-days">
                <div class="reseller-info__sunday">
                  <label class="label reseller-info__sunday-label">{{
                    $t('REGISTER_RESELLER.SUNDAYS_THURSDAYS')
                  }}</label>
                  <div class="reseller-info__sunday-wrapper">
                    <input class="input reseller-info__input" type="text" />
                    <span class="reseller-info__dash">-</span>
                    <input class="input reseller-info__input" type="text" />
                  </div>
                </div>

                <div class="reseller-info__friday">
                  <label class="label reseller-info__friday-label">{{
                    $t('REGISTER_RESELLER.FRIDAY_HOLIDAY_EVES')
                  }}</label>
                  <div class="reseller-info__friday-wrapper">
                    <input class="input reseller-info__input" type="text" />
                    <span class="reseller-info__dash">-</span>
                    <input class="input reseller-info__input" type="text" />
                  </div>
                </div>

                <div class="reseller-info__saturday">
                  <label class="label reseller-info__saturday-label" for>{{
                    $t('REGISTER_RESELLER.SATURDAYS_HOLIDAYS')
                  }}</label>
                  <div class="reseller-info__saturday-wrapper">
                    <input class="input reseller-info__input" type="text" />
                    <span class="reseller-info__dash">-</span>
                    <input class="input reseller-info__input" type="text" />
                  </div>
                </div>
              </div>

              <div class="reseller-info__everyday">
                <input
                  class="reseller-info__checkbox"
                  type="checkbox"
                  id="everyday"
                />
                <label
                  class="label reseller-info__checkbox-label"
                  for="everyday"
                  >24X7</label
                >
              </div>
            </div>
          </div>
          <div class="reseller-info__shaniv-filler reseller-info__case">
            <label class="label reseller-info__label">
                &nbsp;
            </label>
          </div>
          <div v-if="shanivCompanyList && shanivCompanyList.length > 0" v-for="(shaniv_company, index) in shanivCompanyList" class="reseller-info__shaniv-custname reseller-info__case">
            <label class="label reseller-info__label">
              {{ $t('BUSINESS_DETAILS.SHANIV_CUSTNAME', {company: shaniv_company.name}) }}
            </label>
            <InfiniteScrollSelect
              class="input cus-select"
              v-model="newReseller_data[`CUSTNAME_${shaniv_company.name}`]"
              :fetchDataFunction="fetchShanivCustomerOptions"
              :additionalSearchParams="{company: shaniv_company.name}"
            ></InfiniteScrollSelect>
          </div>
        </div>

        <div class="reseller-info__wrapper">
          <div class="reseller-info__email reseller-info__case validation-form-group"
            :class="{ 'form-group--error': $v.newReseller_data.contact_mail.$error }">
            <label class="label reseller-info__label">{{
              $t('COMMON.E_MAIL')
            }}</label>
            <input
              class="input"
              type="email"
              placeholder="email@example.com"
              v-model="newReseller_data.contact_mail"
              @input="$v.newReseller_data.contact_mail.$touch"
            />
            <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newReseller_data.contact_mail.email">
              {{ $t('VALIDATION.INVALID_VALUE') }}
            </div>
          </div>

          <div class="reseller-info__email-2 reseller-info__case validation-form-group"
            :class="{ 'form-group--error': $v.newReseller_data.contact_account_mail.$error }">
            <label class="label reseller-info__label">{{
              $t('REGISTER_RESELLER.EMAIL_ACCOUNTING')
            }}</label>
            <input
              class="input"
              type="email"
              placeholder="email@example.com"
              v-model="newReseller_data.contact_account_mail"
              @input="$v.newReseller_data.contact_account_mail.$touch"
            />
            <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newReseller_data.contact_account_mail.email">
              {{ $t('VALIDATION.INVALID_VALUE') }}
            </div>
          </div>

          <div class="reseller-info__profile reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('REGISTER_RESELLER.VENDOR_PROFILE')
            }}</label>
            <vue-select
              :options="supplier.options"
              class="input cus-select"
              v-model="supplier.selected"
            ></vue-select>
          </div>

          <div class="reseller-info__percent reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('REGISTER_RESELLER.PROFILE_PERCENTAGE_PROFILE')
            }}</label>
            <vue-select
              :options="profit.options"
              class="input cus-select"
              v-model="profit.selected"
            ></vue-select>
          </div>

          <div class="reseller-info__commission reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('REGISTER_RESELLER.FEES_PROFILE')
            }}</label>
            <vue-select
              :options="commission.options"
              class="input cus-select"
              v-model="commission.selected"
            ></vue-select>
          </div>

          <div class="reseller-info__percent-tourism reseller-info__case">
            <label class="label reseller-info__label">{{
              $t('REGISTER_RESELLER.TOURISM_PERCENTAGE_PROFILE')
            }}</label>
            <vue-select
              :options="tourism.options"
              class="input cus-select"
              v-model="tourism.selected"
            ></vue-select>
          </div>
        </div>
      </div>
      <div class="m-t-10 m-b-20">
        <label class="m-b-5 d-block">{{ $t('DESTINATION.TAGS') }}</label>
        <TagsBlockThree :selectedTags="tags"></TagsBlockThree>
      </div>
      
      <hr class="block-hr" v-if="!isAsDistribute">

      <div class="reseller-safe__container m-t-40" v-if="!isAsDistribute">
        <div class="reseller-safe__cover reseller-safe__cover-1">
          <div class="p-relative payment-method ">
            <label class="label">{{
              $t("COMMON.PAYMENT_METHOD")
            }}</label>
            <vue-select
              :options="contract_data.paymentMethod[currentLang]"
              class="input cus-select"
              v-model="contract_data.paymentMethod.selected"
            ></vue-select>
          </div>
          <div class="reseller-safe__days">
            <p class="text reseller-safe__days-text">
              {{ $t("REGISTER_RESELLER.COLLECTION_DAYS") }}:
            </p>
            <div class="reseller-safe__days-wrapper">
              <span 
                class="reseller-safe__day"
                :class="contract_data.monday ? 'reseller-safe__day_active' : ''"
                @click="activeDay('monday')"
              >
                {{ $t("REGISTER_RESELLER.A") }}
              </span>
              <span 
                class="reseller-safe__day"
                :class="contract_data.tuesday ? 'reseller-safe__day_active' : ''"
                @click="activeDay('tuesday')"
              >
                {{ $t("REGISTER_RESELLER.B") }}
              </span>
              <span 
                class="reseller-safe__day"
                :class="contract_data.wednesday ? 'reseller-safe__day_active' : ''"
                @click="activeDay('wednesday')"
              >
                {{ $t("REGISTER_RESELLER.C") }}
              </span>
              <span 
                class="reseller-safe__day"
                :class="contract_data.thursday ? 'reseller-safe__day_active' : ''"
                @click="activeDay('thursday')"
              >
                {{ $t("REGISTER_RESELLER.D") }}
              </span>
              <span 
                class="reseller-safe__day"
                :class="contract_data.friday ? 'reseller-safe__day_active' : ''"
                @click="activeDay('friday')"
              >
                {{ $t("REGISTER_RESELLER.E") }}
              </span>
              <span 
                class="reseller-safe__day"
                :class="contract_data.saturday ? 'reseller-safe__day_active' : ''"
                @click="activeDay('saturday')"
              >
                {{ $t("REGISTER_RESELLER.F") }}
              </span>
            </div>
          </div>
        </div>

        <div class="reseller-safe__cover reseller-safe__cover-2">
          <p class="text reseller-safe__bank-text">
            {{ $t("REGISTER_RESELLER.BANK_ACCOUNT_INFORMATION") }}
          </p>

          <div class="reseller-safe__bank">
            <div class="reseller-safe__bank-name reseller-safe__bank-item">
              <label class="label reseller-safe__label">{{
                $t("REGISTER_RESELLER.BANK")
              }}</label>
              <input class="input reseller-safe__input" type="text" />
            </div>
            <div class="reseller-safe__bank-number reseller-safe__bank-item">
              <label class="label reseller-safe__label">{{
                $t("REGISTER_RESELLER.BANK_NUMBER")
              }}</label>
              <input class="input reseller-safe__input" type="text" />
            </div>
            <div class="reseller-safe__bank-account reseller-safe__bank-item">
              <label class="label reseller-safe__label">{{
                $t("REGISTER_RESELLER.ACCOUNT_NUMBER")
              }}</label>
              <input class="input reseller-safe__input" type="text" />
            </div>
            <div class="reseller-safe__bank-branch reseller-safe__bank-item">
              <label class="label reseller-safe__label">{{
                $t("REGISTER_RESELLER.BRANCH")
              }}</label>
              <input class="input reseller-safe__input" type="text" />
            </div>
          </div>
        </div>

        <div class="d-flex flex-wrap m-t-20" style="max-width: 660px">
          <div class="px10 small-input">
            <label class="label">{{
              $t("REGISTER_RESELLER.LINE_OF_CREDIT")
            }}</label>
            <input class="input" 
              type="text" 
              @keypress="isNumber($event)" 
              v-model="contract_data.frame"
            />
          </div>
          <div class="px10 small-input">
            <label class="label">{{
              $t("RESELLER_DETAILS.TEMPORARY_FRAMEWORK")
            }}</label>
            <input 
              class="input" 
              type="text" 
              @keypress="isNumber($event)" 
              v-model="contract_data.temp_frame"/>
          </div>
          <div class="px10 small-input">
            <label class="label">{{ $t("COMMON.BALANCE") }}</label>
            <input
              class="input color-red1 bg-grey"
              type="text"
              v-model="contract_data.balance"
              @keypress="isNumber($event)"
            />
          </div>
          <div class="px10 small-input m-t-20">
            <label class="label">{{
              $t("RESELLER_DETAILS.BALANCE_FOR_UTILIZATION")
            }}</label>
            <input
              class="input bg-grey"
              type="text"
              v-model="contract_data.utilization"
              @keypress="isNumber($event)"
            />
          </div>
        </div>

        <div class="m-t-20 comment-block">
          <label class="label" for="">
            {{ $t('COMMON.COMMENTS') }}
          </label>
          <textarea
            rows="4"
            class="w-100 bg-white m-b-15 px10 bdbr-2"
            v-model="contract_data.comment"
          >
          </textarea>
        </div>
      </div>

      <hr class="block-hr">

      <div class="form-group-newseller">
        <div class="d-flex flex-wrap m-b-20">
          <div class="px10 w-25 validation-form-group"
            :class="{ 'form-group--error': $v.newUser_data.first_name.$error }">
            <label class="label reseller-info__label">{{
              $t('COMMON.FIRST_NAME')
            }} *</label>
            <input
              class="input"
              type="text"
              v-model="newUser_data.first_name"
              @input="$v.newUser_data.first_name.$touch"
            />
            <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newUser_data.first_name.required">
              {{ $t('VALIDATION.REQUIRED_FIELD') }}
            </div>
          </div>
          <div class="px10 w-25 validation-form-group"
            :class="{ 'form-group--error': $v.newUser_data.last_name.$error }">
            <label class="label reseller-info__label">{{
              $t('COMMON.LAST_NAME')
            }} *</label>
            <input
              class="input"
              type="text"
              v-model="newUser_data.last_name"
              @input="$v.newUser_data.last_name.$touch"
            />
            <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newUser_data.last_name.required">
              {{ $t('VALIDATION.REQUIRED_FIELD') }}
            </div>
          </div>
          <div class="px10 w-25 validation-form-group"
            :class="{ 'form-group--error': $v.newUser_data.israeli_id.$error }">
            <label class="label reseller-info__label">{{
              $t('SUPPLIER_PAYMENT.ID')
            }}</label>
            <input
              class="input"
              type="text"
              v-model="newUser_data.israeli_id"
              @input="$v.newUser_data.israeli_id.$touch"
            />
            <div class="color-red1 fs14 valide-error-msg" v-if="!$v.newUser_data.israeli_id.sameAsData">
              {{ $t('VALIDATION.INVALID_VALUE') }}
            </div>
          </div>
          <div class="px10 w-25 validation-form-group"
            :class="{ 'form-group--error': $v.newUser_data.phone_number.$error }">
            <label class="label reseller-info__label">{{
              $t('TOURISM_PROFIT_PERCENTAGES.MOBILE_PHONE')
            }} *</label>
            <input
              class="input"
              type="text"
              v-model="newUser_data.phone_number"
              @input="$v.newUser_data.phone_number.$touch"
              @keypress="isNumber($event)"
            />
            <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newUser_data.phone_number.required">
              {{ $t('VALIDATION.REQUIRED_FIELD') }}
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap m-b-20">
          <div class="px10 w-50 validation-form-group">
            <label class="label reseller-info__label">{{
              $t('COMMON.ADDRESS')
            }}</label>
            <vue-google-autocomplete
              id="map2"
              classname="input"
              placeholder="הזן כתובת מלאה"
              v-on:placechanged="getAddressDataUser"
              :country="['il']"
              v-model="googleAddressInput"
            >
            </vue-google-autocomplete>
          </div>
          <div class="px10 w-25">
            <label class="label reseller-info__label">{{
              $t('COMMON.E_MAIL')
            }}</label>
            <input
              class="input"
              type="email"
              placeholder="email@example.com"
              v-model="newUser_data.account_mail"
            />
          </div>
          <div class="px10 w-25">
            <label class="label reseller-info__label">{{
              $t('MANAGEMENT.PERMISSIONS')
            }} *</label>
            <vue-select
              :options="permissions.options"
              class="input cus-select"
              v-model="permissions.selected"
            ></vue-select>
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <div class="px10 w-25 validation-form-group"
            :class="{ 'form-group--error': $v.newUser_data.username.$error }">
            <label class="label reseller-info__label">{{
              $t('LOGIN.USERNAME')
            }} *</label>
            <input
              class="input"
              type="text"
              v-model="newUser_data.username"
              @input="$v.newUser_data.username.$touch"
            />
            <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newUser_data.username.required">
              {{ $t('VALIDATION.REQUIRED_FIELD') }}
            </div>
          </div>
          <div class="px10 w-25 validation-form-group"
            :class="{ 'form-group--error': $v.newUser_data.password.$error }">
            <label class="label reseller-info__label">{{
              $t('COMMON.PASSWORD')
            }} *</label>
            <input
              class="input"
              type="text"
              v-model="newUser_data.password"
              @input="$v.newUser_data.password.$touch"
            />
            <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newUser_data.password.required">
              {{ $t('VALIDATION.REQUIRED_FIELD') }}
            </div>
          </div>
          <!-- <div class="px10 w-25">
            <div class="d-flex align-items-center px10">
              <v-checkbox color="#0D3856" class="cus-checkbox"></v-checkbox>
              <label class="px10 fs16 color-primary">{{
                $t('MANAGEMENT.SUCURE_IP')
              }}</label>
            </div>
            <div class="px10">
              <label class="label reseller-info__label">{{
                $t('MANAGEMENT.IP_NUMBER_ADDRESSES')
              }} *</label>
              <input
                class="input"
                type="text"
                v-model="newReseller_data.ID"
              />
            </div>
          </div>
          <div class="px10 w-25">
            <div class="d-flex align-items-center px10">
              <v-checkbox color="#0D3856" class="cus-checkbox"></v-checkbox>
              <label class="px10 fs16 color-primary">{{
                $t('COMMON.SECURE_DEVICE')
              }}</label>
            </div>
            <div class="px10">
              <label class="label reseller-info__label">{{
                $t('MANAGEMENT.SEVERAL_SECURE_DEVICES')
              }} *</label>
              <input
                class="input"
                type="text"
                v-model="newReseller_data.ID"
              />
            </div>
          </div> -->
        </div>
      </div>
      <div class="d-flex m-t-20 m-b-20 bottom-action">
        <button class="primary-btn mx10" @click="save()">
          {{ $t('COMMON.SAVE') }}
        </button>
        <button class="primary-btn mx10 bg-red1" @click="closeModal()">
          {{ $t('COMMON.CANCEL') }}
        </button>
      </div>
    </div>
  </modal>  
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { validationMixin } from "vuelidate";
import { required, email, sameAsData } from "vuelidate/lib/validators";
import InfiniteScrollSelect from "../InfiniteScrollSelect";
import { shaniv_managementService } from "../../services/shaniv_management.service";

import TagsBlockThree from "../TagsBlockThree.vue";
export default {
  name: "NewBusinessModal",
  mixins: [validationMixin],
  components: {
    TagsBlockThree,
    InfiniteScrollSelect
  },
  props: {
    distributor_id: {
      require: true,
      type: [String, Number]
    },
    distribute_section_20: {
      require: true,
      type: Boolean
    }
  },
  validations: {
    newReseller_data: {
      name: {
        required,
      },
      business_license_number: {
        required
      },
      phone_number: {
        required
      },
      city: {
        required
      },
      contact_mail: {
        email
      },
      contact_account_mail: {
        email
      },
    },
    newUser_data: {
      first_name: {
        required
      },
      last_name: {
        required
      },
      israeli_id: {
        sameAsData: function() {
          let id = this.newUser_data.israeli_id;
          id = String(id).trim();
          if (id.length == 0) {
            return true;
          } else if (id.length > 9 || id.length < 5) {
            return false;
          }

          // Pad string with zeros up to 9 digits
          id = id.length < 9 ? ("00000000" + id).slice(-9) : id;

          return Array.from(id, Number)
            .reduce((counter, digit, i) => {
              const step = digit * ((i % 2) + 1);
              return counter + (step > 9 ? step - 9 : step);
            }) % 10 === 0;
        },
      },
      phone_number: {
        required
      },
      account_mail: {
        email
      },
      password: {
        required
      },
      username: {
        required
      },
    }
  },
  data() {
    return {
      isAsDistribute: false,
      newReseller_data: {
        name: "",
        business_license_number: "",
        phone_number: "",
        second_phone_number: "",
        section_20: false,
        city: "",
        street: "",
        // zip_code: "",
        // entrance: "",
        contact_mail: "",
        contact_account_mail: ""
      },
      tags: [],
      contract_data: {
        paymentMethod: {
          selected: {label: "", code: ""},
          he: [
            {label: "הוראת קבע", code: "הוראת קבע"},
            {label: "תשלום לסוכן", code: "תשלום לסוכן"},
            {label: "תשלום מראש", code: "תשלום מראש"}
          ],
          en: [
            {label: "Standing Order", code: "הוראת קבע"},
            {label: "Payment to Agent", code: "תשלום לסוכן"},
            {label: "Prepaid", code: "תשלום מראש"}
          ],
          ar: [
            {label: "הוראת קבע בערבית", code: "הוראת קבע"},
            {label: "תשלום לסוכן בערבית", code: "תשלום לסוכן"},
            {label: "תשלום מראש בערבית", code: "תשלום מראש"}
          ]
        },
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        frame: "",
        temp_frame: "",
        comment: "",
        balance: "",
        utilization: ""
      },
      newUser_data: {
        first_name: "",
        last_name: "",
        israeli_id: "",
        phone_number: "",
        account_mail: "",
        password: "",
        username: "",
        city: "",
        street: "",
        country: "",
      },
      googleAddressInput: '',
      agent_id: {
        options: [],
        selected: '',
      },
      commission: {
        // options: [{ 
        //   label: '', 
        //   code: null
        // }],
        // selected: { 
        //   label: '', 
        //   code: null
        // }
        options: [],
        selected: '',
      },
      profit: {
        options: [],
        selected: '',
      },
      supplier: {
        options: [],
        selected: '',
      },
      permissions: {
        options: [],
        selected: '',
      },
      tourism:{
        options: [],
        selected: '',
      },
      test: {
        options: [],
        selected: '',
      },
    }
  },
  computed: {
    ...mapState({
      agentsResponsible: (state) => state.seller_tourism_percentage.agentsResponsible,
      rolesList: (state) => state.seller_tourism_percentage.rolesList,
      businessProfileData: (state) => state.seller_tourism_percentage.businessProfileData,
      shanivCompanyList: (state) => state.shaniv_management.companyList,
      tourismProfile: (state) => state.category_products.tourismProfile
    })
  },
  async created() {
    if (this.agentsResponsible.length == 0) {
      await this.getAgentsResponsible();
    }

    if (this.agentsResponsible.length != 0) {
      this.agentsResponsible.forEach(el => {
        this.agent_id.options.push({
          label: el.name,
          code: el.id
        }); 
      });
      this.agent_id.selected = this.agent_id.options[0];
    }

    if (this.businessProfileData.length == 0) {
      await this.getBusinessProfile();
    }

    if (this.businessProfileData.length != 0) {
      this.businessProfileData.commission.forEach((el) => {
        let option = { label: el.name, code: el.id, is_default: el.is_default };
        this.commission.options.push(option);
        if (el.is_default) {
          this.commission.selected = option;
        }
      });
      if (!this.commission.selected) {
        this.commission.selected = this.commission.options[0];
      }


      this.businessProfileData.profit.forEach((el) => {
        let option = { label: el.name, code: el.id, is_default: el.is_default };
        this.profit.options.push(option);
        if (el.is_default) {
          this.profit.selected = option;
        }
      });
      if (!this.profit.selected) {
        this.profit.selected = this.profit.options[0];
      }

      this.businessProfileData.supplier.forEach((el) => {
        let option = { label: el.name, code: el.id, is_default: el.is_default };
        this.supplier.options.push(option);
        if (el.is_default) {
            this.supplier.selected = option;
        }
      });
      if (!this.supplier.selected) {
        this.supplier.selected = this.supplier.options[0];
      }
    }

    if (this.rolesList.length == 0) {
      await this.getRoles();
    }
    if (this.rolesList.length != 0) {
      this.rolesList.forEach(el => {
        this.permissions.options.push({
          label: el.name,
          code: el.id
        })
      });
      this.permissions.selected = this.permissions.options.find(el => {
        return el.label == "משווק";
      });
    }
    if (this.tourismProfile.length == 0) {
      await this.getTourismProfile();
    }

    if (this.tourismProfile && this.tourismProfile.length != 0) {
        this.tourismProfile.forEach((el) => {
          let option = { label: el.tour_name, code: el.id, is_default: el.is_default };
          this.tourism.options.push(option);
          if (el.is_default) {
            this.tourism.selected = option;
          }
        });
        if (!this.tourism.selected) {
          this.tourism.selected = this.tourism.options[0];
        }
    }
  },
  methods: {
    ...mapActions('seller_tourism_percentage', {
      getAgentsResponsible: 'getAgentsResponsible',
      getBusinessProfile: 'getBusinessProfile',
      getRoles: 'getRoles',
      getBusiness: 'getBusiness',
      updateBusinessData: "updateBusinessData" 
    }),
    ...mapActions('shaniv_management', {
      createReseller: 'createReseller',
      createUser: 'createUser',
      createDistributes: 'createDistributes',
      verifyFields: 'verifyFields',
    }),
    ...mapActions("alertTwo", {
      errorAlert: "error"
    }),
    ...mapActions("category_products", {
      getTourismProfile: "getTourismProfile"
    }), 
    async save() {
      this.$v.$touch();
      if (
        this.$v.$anyError
      ) {
        return;
      }

      let userNameVerifyData = {
        field: 'login_name',
        value: this.newUser_data.username
      }
      let idVerifyData = {
        field: 'israeli_id',
        value: this.newUser_data.israeli_id
      }
      this.verifyFields(userNameVerifyData).then(verifyUname => {
        if (!verifyUname) {
          this.verifyFields(idVerifyData).then(verifyId => {
            if (!verifyId) {
              let PLNAMEs = [];
              let ShanivCustomers = [];
              for (let shaniv_company of this.shanivCompanyList) {
                PLNAMEs.push({
                  company: shaniv_company.name,
                  PLNAME: this.newReseller_data[`PLNAME_${shaniv_company.name}`]
                });
                ShanivCustomers.push({
                  company: shaniv_company.name,
                  CUSTNAME: this.newReseller_data[`CUSTNAME_${shaniv_company.name}`]
                });
              }
              let sellerParams = this.newReseller_data;
              sellerParams.agent_id = this.agent_id.selected.code;
              sellerParams.commissionsProfile_id = this.commission.selected.code;
              sellerParams.profitPercentagesProfile_id = this.profit.selected.code;
              sellerParams.suppliers_profile_id = this.supplier.selected.code;
              sellerParams.tourism_profile_id = this.tourism.selected.code;
              sellerParams.tags = JSON.stringify(this.tags);
              sellerParams.PLNAMEs = PLNAMEs;
              sellerParams.ShanivCustomers = ShanivCustomers;

              let newUser = this.newUser_data;
              newUser.role_id = this.permissions.selected.code;

              if (this.isAsDistribute) {
                this.createDistributes(sellerParams).then(res => {
                  if (res.message == "success") {
                    this.getBusiness({ id: res.id }).then(sellers => {
                      let seller = sellers.find(s => s.distributor_id == res.id)
                      newUser.business_id = seller.id;
                      this.createUser(newUser);
                    })
                  }
                })
              } else {
                sellerParams.distributor_id = this.distributor_id;
                this.createReseller(sellerParams).then( async res => {
                  if (res.message == "success") {
                    newUser.business_id = res.id;
                    await this.createUser(newUser);

                    const contractParams = {
                      balance: this.contract_data.balance,
                      // business_identifier: null,
                      frame: this.contract_data.frame,
                      friday: this.contract_data.friday,
                      monday: this.contract_data.monday,
                      payment_method: this.contract_data.paymentMethod.selected.code,
                      sunday: this.contract_data.sunday,
                      temp_frame: this.contract_data.temp_frame,
                      thursday: this.contract_data.thursday,
                      tuesday: this.contract_data.tuesday,
                      wednesday: this.contract_data.wednesday,
                    };
                    const id = res.id;
                    await this.updateBusinessData({
                      id: id,
                      params: contractParams,
                    });

                    this.$emit("setNewseller", res.id);
                  }
                })
              }

            } else {
              this.errorAlert( "Id is dulplicated" );
              return false;
            }
          })
        } else {
          this.errorAlert( "User name is dulplicated" );
          return false;
        }
      });

      this.closeModal();
    },
    closeModal() {
      this.$modal.hide('newBusinessModal');
    },
    getAddressDataUser(address) {
      this.newUser_data.country = address.country;
      this.newUser_data.city = address.locality;
      this.newUser_data.street = address.route;
    },
    getAddressDataReseller(address) {
      this.newReseller_data.city = address.locality;
      this.newReseller_data.street = address.route;
    },
    activeDay(day) {
      this.contract_data[day] = !this.contract_data[day];
    },
    async fetchShanivCustomerOptions(params) {
      let searchParams = {};
      if (params) {
        searchParams.page = params.page || 0;
        searchParams.per_page = params.per_page || 50;
        if (params.search) {
          searchParams.CUSTNAME = params.search;
        }
        if (params.additionalSearchParams) {
          searchParams = {...searchParams, ...params.additionalSearchParams};
        }
      }
      return shaniv_managementService.getCustomerOptions(searchParams).then(
        (result) => {
          let loaded_items = result.data;
          loaded_items = Array.isArray(loaded_items) ? loaded_items : [];

          result.data = loaded_items.map(i => i.CUSTNAME);
          result.total = result.total || 0;

          return result;
        },
        (error) => {
          return error;
        });
    },
  }

}
</script>
<style lang="scss">
 .newBusinessModal {
    .vm--modal {
      max-height: 85% !important;
    }
 }
</style>

<style lang="scss" scoped>
.reseller-info__work-wrapper {
  height: 238.42px;
}
.reseller-info__shaniv-filler {
  height: calc(61.2727px - 1px);
}
  .newBusinessModal {
    .block-hr {
      margin: 40px -10px;
      border-top: 1px;
    }
    .modal-content {
      font-size: 16px;
      padding: 30px;
      border: none;
    }
    .modal-footer {
      justify-content: flex-end;
      padding: 10px;
      border-top: 1px solid #dedede;
    }
    .primary-btn {
      width: auto;
      padding: 0 20px;
    }
    .customer-number {
      width: calc(50% - 10px);
    }
    .reseller-safe__container {
      margin: unset;
    }
    .form-group-newseller {
      margin: 0 -10px;
    }
    .comment-block {
      min-width: 250px;
    }

    .bottom-action {
      margin: 0 -10px;
      .primary-btn {
        width: 100%;
        max-width: 200px;
      }
    }
    .reseller-info__label,
    .reseller-safe__label {
      margin-bottom: 0;
    }
  }
</style>